<template>
  <div class="loading">
    <v-skeleton-loader style="height: 10rem" type="image"></v-skeleton-loader>

    <v-skeleton-loader
      style="margin-top: 2.7rem"
      type="chip"
    ></v-skeleton-loader>
    <v-skeleton-loader
      style="margin: 0.8rem 0 2rem 0"
      type="heading"
    ></v-skeleton-loader>

    <v-skeleton-loader
      style="margin-top: 1.7rem"
      type="chip"
    ></v-skeleton-loader>

    <v-skeleton-loader
      style="margin-top: 1rem"
      type="paragraph@2"
    ></v-skeleton-loader>

    <v-skeleton-loader
      style="margin-top: 1.7rem"
      type="chip"
    ></v-skeleton-loader>
    <v-skeleton-loader
      style="margin-top: 1rem"
      type="paragraph"
    ></v-skeleton-loader>
  </div>
</template>

<script>
export default {
  name: "indexLoading",
};
</script>

<style lang="scss" scoped>
.loading {
  height: auto;
  padding: 0.7rem;
  min-height: 20rem;
}
</style>
