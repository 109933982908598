import $api from "@/api/api";
import { isEmpty } from "@/utils/common";
export const likeWord = {
  data() {
    return {};
  },
  methods: {
    deleteToWordTable(id, word) {
      let postData = {
        id: id,
      };
      $api
        .removeToUserWordTable(postData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.isInWordTable = false;
            // 从本地单词本缓存中删除
            this.removeLocalWordTable(id);
            this.$toast.success("从单词本删除 " + word + " 成功！");
          } else {
            // 不弹出提示打扰用户了
            console.log("从单词本删除失败！", res.msg);
            this.$toast.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.deleteFinally();
        });
    },
    removeLocalWordTable(id) {
      let loaclWordTable = this.$store.getters.wordTable;
      for (let i in loaclWordTable) {
        loaclWordTable[i] = loaclWordTable[i].filter((value) => {
          return value.id !== id;
        });
      }
      this.$store.dispatch("setWordTable", loaclWordTable);
    },
    addToWordTable(id, word) {
      this.likeBtnLoading = true;
      let postData;
      if (!isEmpty(id)) {
        postData = {
          id: id,
        };
      } else {
        postData = {
          word: word,
        };
      }
      $api
        .addToUserWordTable(postData)
        .then((ret) => {
          let res = ret.data;
          if (res.code === 0) {
            this.isInWordTable = true;
            this.$toast.success("添加 " + res.data.word + " 到单词本成功！");
          } else {
            // 不弹出提示打扰用户了
            console.log("添加到单词本失败！", res.msg);
            this.$toast.error(res.msg);
          }
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          this.likeBtnLoading = false;
        });
    },
  },
};
