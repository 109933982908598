<template>
  <div
    id="word"
    :style="`background-color: ${appThemeConfig.appBgColor}; margin-top: ${
      isApp ? systemBarHeight : 0
    }px`"
  >
    <word-detail-app-head
      ref="detailAppHead"
      :isLoading="isLoading"
      :searchWord="searchWord"
      :likeBtnLoading="likeBtnLoading"
      :isInWordTable="isInWordTable"
      :showLike="!isEmpty(wordDetail)"
      @addWordTable="addWordTable"
      @deleteWordTable="deleteWordTable"
      :key="initTimes"
    >
    </word-detail-app-head>
    <div
      id="word-scroll"
      class="word-scroll show-scroll"
      :style="`height:${
        isApp ? screenHeight - systemBarHeight - 56 : screenHeight - 56
      }px;`"
    >
      <van-pull-refresh
        style="min-height: 100%"
        v-model="isRefreshing"
        @refresh="onRefresh"
      >
        <div class="word-content">
          <div v-show="false">
            <audio ref="pronounce" v-if="audio" preload="auto" :src="audio" />
          </div>
          <div class="search-word">
            {{ searchWord }}
          </div>
          <v-card
            class="datail-card"
            elevation="0"
            v-show="wordDetail.phonetic || wordDetail.translation"
          >
            <div class="left">
              <div class="phonetic" v-show="wordDetail.phonetic">
                <div class="item-title">音标：</div>
                <van-tag class="tag" size="large"
                  >/ {{ wordDetail.phonetic }} /</van-tag
                >
              </div>

              <div class="translation" v-show="wordDetail.translation">
                <!--                <div class="item-title">中文释义：</div>-->
                <div
                  class="item-translation"
                  v-html="wordDetail.translation"
                ></div>
              </div>
            </div>
            <div class="right" v-show="audio">
              <v-btn
                fab
                small
                elevation="1"
                :disabled="isPlaying"
                @click="playAudio"
              >
                <v-icon color="blue-grey darken-2">mdi-account-voice</v-icon>
              </v-btn>
            </div>
          </v-card>

          <div
            class="importance"
            v-show="
              wordDetail.collins || (wordDetail.frq && wordDetail.frq !== 0)
            "
          >
            <div class="rate" v-show="wordDetail.collins">
              <span>柯林斯星级</span>
              <v-rating
                :value="wordDetail.collins"
                background-color="orange lighten-3"
                color="orange"
                x-small
                dense
                readonly
              ></v-rating>
            </div>
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <div
                  class="frq"
                  v-show="wordDetail.frq && wordDetail.frq !== 0"
                  v-bind="attrs"
                  v-on="on"
                  :style="
                    wordDetail.collins
                      ? 'text-align: right;'
                      : 'text-align: left;'
                  "
                >
                  词频顺序
                  <span class="value">{{ wordDetail.frq }}</span>
                </div>
              </template>
              <span>数据源自当代语料库词频顺序</span>
            </v-tooltip>
            <div class="exams" v-show="wordDetail.tag">
              <div class="exam-title">涉及考试</div>
              <div class="group">
                <v-chip
                  class="span"
                  x-small
                  v-for="(item, index) in wordDetail.tagList"
                  :key="index"
                >
                  {{ item }}
                </v-chip>
              </div>
            </div>
          </div>

          <div
            class="higher"
            v-show="wordDetail.definition || wordDetail.exchange"
          >
            <div class="higher-title" v-show="wordDetail.definition">
              <v-btn elevation="0" class="btn-disabled" fab x-small>
                <v-icon color="light-blue darken-2"> fas fa-globe </v-icon>
              </v-btn>
              <span>英英释义</span>
            </div>
            <div class="definition" v-show="wordDetail.definition">
              <div class="item-definition" v-html="wordDetail.definition"></div>
            </div>

            <div class="higher-title" v-show="wordDetail.exchange">
              <v-btn elevation="0" class="btn-disabled" fab x-small>
                <v-icon color="green lighten-1">
                  fas fa-sort-numeric-up
                </v-icon>
              </v-btn>
              <span>词性转换</span>
            </div>
            <div class="transform" v-show="wordDetail.exchange">
              <div
                class="item-transform"
                v-for="(item, index) in wordDetail.exchangeList"
                :key="index"
              >
                <span class="lable">{{ getExchangeName(item[0]) }}：</span
                ><span class="word">{{ item[1] }}</span>
              </div>
            </div>
          </div>
          <error-page
            :offLine="offLine"
            @doRefresh="onRefresh"
            v-if="!isLoading && !wordDetail.translation && !wordDetail.phonetic"
          ></error-page>
          <index-loading
            v-show="
              isLoading && !wordDetail.translation && !wordDetail.phonetic
            "
          ></index-loading>
          <div
            class="footer-takeplace"
            v-show="wordDetail.translation || wordDetail.phonetic"
          ></div>
        </div>
      </van-pull-refresh>
    </div>
  </div>
</template>

<script>
import { Button } from "vant";
import { Tag } from "vant";
import { mapGetters } from "vuex";
import { buttonVibrate, setSystemColor } from "@/mixin/mixin";
import WordDetailAppHead from "@/views/wordDetail/components/wordDetailAppHead";
import { isEmpty } from "@/utils/common";
import $api from "@/api/api";
import IndexLoading from "@/views/wordDetail/components/indexLoading";
import { likeWord } from "@/mixin/wordTableMixin";

export default {
  name: "wordDetailIndex",
  mixins: [setSystemColor, buttonVibrate, likeWord],
  components: {
    ErrorPage: () => import("@/views/wordDetail/components/errorPage"),
    IndexLoading,
    WordDetailAppHead,
    [Button.name]: Button,
    vanTag: Tag,
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      appTheme: "appTheme",
      appThemeConfig: "appThemeConfig",
      systemBarHeight: "systemBarHeight",
      wordSearchHistory: "wordSearchHistory",
      screenHeight: "screenHeight",
    }),
    getExchangeName() {
      return function (type) {
        let lable;
        if (type === "p") {
          lable = "过去式";
        }
        if (type === "d") {
          lable = "过去分词";
        }
        if (type === "i") {
          lable = "现在分词";
        }
        if (type === "3") {
          lable = "第三人称单数";
        }
        if (type === "r") {
          lable = "比较级";
        }
        if (type === "t") {
          lable = "最高级";
        }
        if (type === "s") {
          lable = "名词复数";
        }
        if (type === "0") {
          lable = "Lemma";
        }
        if (type === "1") {
          lable = "Lemma变换";
        }
        return lable;
      };
    },
  },
  data: () => ({
    audio: null,
    isPlaying: false,
    playInterval: null,
    offLine: false,
    isLoading: true,
    isRefreshing: false,
    searchWord: null,
    searchWordId: null,
    wordDetail: {},
    firstCreat: true,
    likeBtnLoading: false,
    isInWordTable: false,
    initTimes: 0,
  }),
  methods: {
    isEmpty,
    deleteWordTable() {
      this.deleteToWordTable(this.searchWordId, this.searchWord);
    },
    addWordTable() {
      this.addToWordTable(this.searchWordId, this.searchWord);
    },
    deleteFinally() {
      this.$refs.detailAppHead.deleteLoading = false;
      this.$refs.detailAppHead.deleteConfirmDialog = false;
    },
    playAudio() {
      clearInterval(this.playInterval);
      this.playInterval = null;
      this.isPlaying = true;
      this.zzzMid();
      this.$refs.pronounce
        .play()
        .catch((err) => {
          console.log(err);
          this.$toast("播放出错,请重试!");
          let temp = this.audio;
          this.audio = null;
          setTimeout(() => {
            this.audio = temp;
            this.isPlaying = false;
          }, 500);
        })
        .finally(() => {
          this.playInterval = setInterval(() => {
            if (this.$refs.pronounce) {
              if (this.$refs.pronounce.ended) {
                this.isPlaying = false;
                clearInterval(this.playInterval);
              }
            } else {
              this.isPlaying = false;
              clearInterval(this.playInterval);
            }
          }, 250);
        });
    },
    onRefresh() {
      this.getWordDetail();
      this.isRefreshing = false;
    },
    //存入搜索历史记录
    setWordHistory(word) {
      let isnew = true;
      this.wordSearchHistory.some((item) => {
        if (item === word) {
          isnew = false;
        }
      });
      if (isnew) {
        let arr = this.wordSearchHistory;
        arr.unshift(word);
        if (arr.length > 15) {
          arr.pop();
        }
        this.$store.dispatch("setWordSearchHistory", arr);
      }
    },
    wordFormart(word) {
      word = word.replace(/\\n/g, "<br />");
      word = word.replace(/&nbsp;/g, " ");
      return word;
    },
    englishWordFormart(word) {
      word = word.replace(/\\n/g, "<p style='margin-bottom: 0.5rem'></p>");
      word = word.replace(/&nbsp;/g, " ");
      return word;
    },
    examFormart(word) {
      word = word.replace(/zk/g, "中考");
      word = word.replace(/gk/g, "高考");
      word = word.replace(/ky/g, "考研");
      return word.split(" ");
    },
    getWordDetail() {
      this.isLoading = true;
      let getData;
      if (!isEmpty(this.searchWordId)) {
        getData = {
          id: this.searchWordId,
        };
      } else {
        getData = {
          word: this.searchWord,
        };
      }
      $api
        .getWordDetail(getData)
        .then((ret) => {
          this.offLine = false;
          let res = ret.data;
          if (res.code === 0) {
            this.wordDetail = res.data;
            this.searchWord = this.wordDetail.word;
            this.searchWordId = this.wordDetail.id;
            this.isInWordTable = this.wordDetail.isInWordTable;
            if (this.wordDetail.translation) {
              this.wordDetail.translation = this.wordFormart(
                this.wordDetail.translation
              );
            }
            if (this.wordDetail.definition) {
              this.wordDetail.definition = this.englishWordFormart(
                this.wordDetail.definition
              );
            }
            if (this.wordDetail.tag) {
              this.wordDetail.tagList = this.examFormart(this.wordDetail.tag);
            }
            if (this.wordDetail.exchange) {
              this.wordDetail.exchangeList =
                this.wordDetail.exchange.split("/");
              this.wordDetail.exchangeList = this.wordDetail.exchangeList.map(
                (item) => {
                  return item.split(":");
                }
              );
            }
            if (!isEmpty(this.wordDetail.audio)) {
              this.audio = this.wordDetail.audio;
            } else {
              if (/^[A-Za-z]+$/g.test(this.searchWord)) {
                let reqData = {
                  word: this.searchWord,
                };
                $api
                  .getWordPronounce(reqData)
                  .then((ret) => {
                    let res = ret.data;
                    if (res.code === 0 && res.data.sdkAudio) {
                      this.audio = res.data.sdkAudio;
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }
            this.setWordHistory(this.searchWord);
          } else {
            this.$toast(res.msg);
          }
        })
        // eslint-disable-next-line no-unused-vars
        .catch((err) => {
          if (err !== "offline") {
            console.log(err);
            this.$toast("数据解析错误：" + err);
          } else {
            this.offLine = true;
          }
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    init() {
      this.$store.dispatch("setShowSysAppTab", false);
      this.$store.dispatch("setAppSystemBarColor", "#ffffff");
      this.setSystemFootColor(this.appThemeConfig.footerBarColer);
      const query = this.$route.query;
      this.searchWord = query.word;
      this.searchWordId = query.id;
      this.getWordDetail();
    },
  },
  created() {
    console.info("wordDetail组件被创建");
    this.init();
  },
  mounted() {
    console.info("wordDetail组件被挂载");
  },
  activated() {
    console.info("wordDetail组件被激活");
    if (this.firstCreat) {
      this.firstCreat = false;
    } else {
      this.initTimes += 1;
      document.getElementById("word-scroll").scrollTo(0, 0);
      this.audio = null;
      this.init();
    }
  },
  deactivated() {
    console.info("wordDetail组件被暂停");
  },
};
</script>

<style lang="scss" scoped>
#word {
  height: 100%;
  overflow: hidden;
  .word-scroll {
    overflow-y: auto;

    .word-content {
      width: 100%;
      max-width: 100%;
      min-height: 90vh;
      padding: 0 0.5rem;
      word-wrap: break-word;

      .search-word {
        margin: 1.9rem 0.8rem 0.7rem 0.8rem;
        font-size: 2.3rem;
        font-weight: 500;
        font-family: dosisbold, sans-serif;
      }

      .footer-takeplace {
        width: 100%;
        height: 7.5rem;
      }

      .importance {
        margin: 1.5rem 0.5rem 0.7rem 0.5rem;
        padding: 0 0.4rem;
        font-size: 0.9rem;
        letter-spacing: 0.03rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        .rate {
          width: 50%;
          display: flex;
          align-items: center;
          font-size: 0.9rem;
          color: #404040;
          > span {
            line-height: 2rem;
            margin: 0 0.3rem 0 0;
          }
        }
        .frq {
          width: 50%;
          margin: 0.1rem 0 0 0;
          font-size: 0.9rem;
          color: #404040;
          .value {
            font-size: 0.9rem;
            color: #909090;
            font-weight: bold;
          }
        }
        .exams {
          margin-top: 0.5rem;
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          .exam-title {
            min-width: 4.2rem;
          }
          .group {
            max-width: 100%;
            height: 2rem;
            overflow-x: scroll;
            white-space: nowrap;
            display: flex;
            justify-content: flex-start;
            flex-wrap: nowrap;
            align-items: center;
            &::-webkit-scrollbar {
              height: 0 !important;
            }
            .span {
              margin-right: 0.4rem;
              color: #707070;
              flex-shrink: 0;
            }
          }
        }
      }

      .higher {
        margin: 0.7rem 0.5rem;
        padding: 0.7rem 0.4rem;
        min-height: 8rem;
        font-size: 0.9rem;
        letter-spacing: 0.03rem;
        .higher-title {
          margin-left: -0.2rem;
          //margin-top: 0.5rem;
          font-size: 1.1rem;
          height: 3rem;
          line-height: 2rem;
          font-weight: bold;
          display: flex;
          align-items: center;
          letter-spacing: 0.08rem;
          > span {
            margin-left: 0.3rem;
          }
        }
        .definition {
          margin: 0.9rem 0.2rem 1.5rem 0.2rem;
          .item-definition {
            color: #444444;
            font-weight: 300;
          }
        }
        .transform {
          margin: 0.9rem 0.2rem 1.5rem 0.2rem;
          .item-transform {
            margin: 0.5rem 0;
            display: flex;
            justify-content: flex-start;
            align-items: flex-end;
            .lable {
              color: #303030;
            }
            .word {
              margin-left: 0.2rem;
              color: #505050;
              font-weight: 300;
            }
          }
        }
      }

      .datail-card {
        border-radius: 1rem;
        background-color: #f1f3f8;
        margin: 1rem 0.7rem 1.2rem 0.7rem;
        padding: 0.6rem 0.9rem;
        min-height: 8rem;
        font-size: 0.85rem;
        letter-spacing: 0.05rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .item-title {
          width: 3.2rem;
          min-width: 3.2rem;
        }

        .left {
          height: 100%;
          min-width: 16rem;
          .phonetic {
            margin: 1rem 0.2rem;
            display: flex;
            align-items: center;
            justify-content: flex-start;
            .tag {
              font-size: 0.9rem;
              padding: 0.3rem 0.7rem;
              background-color: #d9dce3;
              color: #404040;
              letter-spacing: 0.05rem;
            }
          }
        }

        .right {
          display: flex;
          align-items: center;
          justify-content: center;
          min-width: 5rem;
        }

        .translation {
          margin: 1rem 0.2rem;
          display: flex;
          justify-content: flex-start;
          flex-direction: column;
          .item-title {
            width: 5rem;
            margin: 0 0 0.5rem 0;
          }
          .item-translation {
            font-weight: bold;
            line-height: 1.5rem;
          }
        }
      }
    }
  }
}
</style>
