<template>
  <v-app-bar
    :color="appThemeConfig.headColor"
    :dark="appThemeConfig.dark"
    elevate-on-scroll
    scroll-target="#word-scroll"
    class="app-bar rounded-b-lg"
  >
    <v-btn @touchstart="zzzMid" icon @click="goBack">
      <v-icon>mdi-arrow-left</v-icon>
    </v-btn>

    <v-app-bar-title
      class="cut-words"
      style="max-width: 15rem; font-size: 16px; color: #7a7a7a"
      >单词详情：{{ searchWord }}</v-app-bar-title
    >

    <v-progress-linear
      :active="isLoading"
      :indeterminate="true"
      height="2"
      absolute
      bottom
      color="deep-purple accent-1"
    ></v-progress-linear>

    <v-spacer></v-spacer>
    <v-row justify="center">
      <v-dialog v-model="deleteConfirmDialog" persistent max-width="290">
        <v-card>
          <v-card-title class="text-h5">
            确认从单词本移除此单词？
          </v-card-title>
          <v-card-text
            >从你的单词本移除该单词意味着将清空此单词的所有学习记录！</v-card-text
          >
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              color="green darken-1"
              text
              @click="deleteConfirmDialog = false"
            >
              取消
            </v-btn>
            <v-btn
              color="green darken-1"
              text
              @click="confirmDelete"
              :loading="deleteLoading"
            >
              确认移除
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <v-fade-transition>
      <v-btn v-show="showLike" :loading="likeBtnLoading" @click="likeWord" icon>
        <v-icon :color="isInWordTable ? 'deep-orange lighten-2' : ''"
          >mdi-heart</v-icon
        >
      </v-btn>
    </v-fade-transition>
  </v-app-bar>
</template>

<script>
import { mapGetters } from "vuex";
import { buttonVibrate } from "@/mixin/mixin";

export default {
  name: "wordDetailAppHead.vue",
  mixins: [buttonVibrate],
  props: {
    searchWord: {
      type: String,
      default: "未找到单词",
    },
    isLoading: {
      type: Boolean,
      default: true,
    },
    likeBtnLoading: {
      type: Boolean,
      default: false,
    },
    isInWordTable: {
      type: Boolean,
      default: false,
    },
    showLike: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters({
      isApp: "isApp",
      systemBarHeight: "systemBarHeight",
      appThemeConfig: "appThemeConfig",
    }),
  },
  data: () => ({
    deleteConfirmDialog: false,
    deleteLoading: false,
  }),
  methods: {
    goBack() {
      this.$router.back();
    },
    likeWord() {
      this.zzzShort();
      if (!this.isInWordTable) {
        this.$emit("addWordTable");
      } else {
        this.deleteConfirmDialog = true;
      }
    },
    confirmDelete() {
      this.zzzShort();
      this.deleteLoading = true;
      this.$emit("deleteWordTable");
    },
  },
};
</script>

<style lang="scss" scoped>
.app-bar {
  z-index: 3;
  vertical-align: middle;
}
</style>
